<template>
  <div class="full-page">
    <Section1/>
    <section2></section2>
    <section3></section3>
    <section4></section4>
<!--    <section5></section5>-->
    <Footer/>
  </div>
</template>

<script setup>
// @ is an alias to /src
import Section1 from '@/views/HomeViewComponents/Section-1.vue'
import section2 from '@/views/HomeViewComponents/section-2.vue'
import section3 from '@/views/HomeViewComponents/section-3.vue'
import section4 from '@/views/HomeViewComponents/section-4.vue'
import section5 from '@/views/HomeViewComponents/section-5.vue'
import Footer from '@/views/HomeViewComponents/Footer.vue'

import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig } = useGetGlobalConfig()
console.log($globalConfig)
// export default {
//   name: 'HomeView',
//   components: {
//     section2,
//     section3,
//     section4,
//     section5,
//     Footer
//   }
// }
</script>
<style lang="scss">
.full-page{
  height: 100%;
  //overflow: hidden;
}
</style>
