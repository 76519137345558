<script setup>

import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig: { sectionThree } } = useGetGlobalConfig()
</script>

<template>
  <div class="section-3">
    <el-carousel trigger="click" height="680px">
      <el-carousel-item v-for="(item, index) in sectionThree.carIntroduceList" :key="index">
        <div class="top-content">
          <div class="main-title">{{ item.mainTitle }}</div>
          <div class="main-tips">{{ item.mainTips }}</div>
          <img class="main-img" :src="item.mainImg"/>
        </div>
        <div class="intro-list">
          <div class="intro-item" v-for="(info, j) in item.mainList" :key="j">
            <div class="intro-title">{{ info.title }}</div>
            <div class="intro-info">{{ info.tips }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<style scoped lang="scss">
.section-3{
  padding: 100px 200px;
  background: #f8f8f9;
  box-sizing: border-box;
  .top-content{
    text-align: center;
    .main-title{
      font-size: 36px;
      color: rgb(51,51,51);
      font-weight: 700;
      text-align: center;
    }
    .main-tips{
      font-size: 25px;
      color: rgb(153, 153, 153);
      line-height: 60px;
    }
    .main-img{
      max-width: 800px;
      height: auto;
      margin-top: 30px;
    }
  }
  .intro-list{
    display: flex;
    .intro-item{
      flex: 1;
      padding: 20px;
      margin-top: 20px;
      .intro-title{
        color: #333;
        font-size: 20px;
        margin-top: 10px;
        font-weight: 400px;
      }
    }
  }
}
</style>