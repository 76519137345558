<script setup>

import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig: { sectionOne } } = useGetGlobalConfig()
</script>

<template>
  <div class="section-1">
    <video class="video-content" autoplay muted loop :src="sectionOne.bannerBg"></video>
    <div class="circle">
      <div class="title fadeInRight">{{ sectionOne.mainTitle }}</div>
      <div class="text fadeInRight">{{ sectionOne.englishMainTitle }}</div>
      <div class="text-info fadeInRight">{{ sectionOne.mainTips }}</div>
      <div class="code-img">
        <img :src="sectionOne.codeImg"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.section-1{
  height: 100%;
  position: relative;
  .video-content{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%
  }
  .circle{
    text-align: left;
    color: #fff;
    padding: 96px;
    box-sizing: border-box;
    width: 580px;
    height: 580px;
    border-radius: 50%;
    background: rgba(23,53,73,.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .title {
      font-size: 48px;
    }
    .text{
      line-height: 50px;
      font-size: 26px;
    }
    .text-info{
      line-height: 80px;
      font-size: 24px;
    }
    .code-img{
      width: 140px;
      height: 140px;
      padding: 10px;
      border: 2px solid #fff;
      border-radius: 6px;
      img{
        width: 140px;
        height: 140px;
      }
    }
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%,0,0)
  }

  to {
    opacity: 1;
    transform: none
  }
}

.fadeInRight {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInRight
}
</style>