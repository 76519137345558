<script setup>

import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig: { footer } } = useGetGlobalConfig()
const goJump = (key) => {
  switch (key) {
    case 1:
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
      break;
    case 2:
      window.open('https://beian.mps.gov.cn/#/query/webSearch')
      break;
  }
}

</script>

<template>
  <div class="footer">
    <div class="footer-top">
      <img class="footer-logo" :src="footer.footerLogo">
      <div class="customer">
        <div class="text">客服热线</div>
        <div class="phone">
          <img class="phone-icon" src="https://dytz-website.oss-accelerate.aliyuncs.com/homesite_forntend_v3/phone.png"/>
          <div class="phone-number">{{ footer.customerPhone}}</div>
        </div>
      </div>
    </div>
    <div class="copy-right">
      {{ footer.copyright }}
      <span @click="goJump(2)">{{ footer.recordNumber }}</span>
      <span @click="goJump(1)">{{ footer.internetNumber }}</span>
    </div></div>
</template>

<style scoped lang="scss">
.footer{
  padding: 60px 100px;
  background: #262e35;
  .footer-top{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .footer-logo{
      margin-right: 30px;
      display: block;
    }
    .customer{
      color: #a3976e;
      .text{
        font-size: 18px;
        margin-top: 10px;
        padding-left: 30px;
        border-left: 2px solid #3f4c56;
        text-align: left;
      }
      .phone{
        font-size: 24px;
        font-weight: 700;
        padding-left: 30px;
        border-left: 2px solid #3f4c56;
        display: flex;
        align-items: center;
        .phone-icon{
          padding: 0px;
          margin: 0px 10px 0px 0px;
        }
      }
    }

  }
  .copy-right{
    font-size: 16px;
    color: #8d9195;
    margin-top: 40px;
    line-height: 30px;
    text-align: left;
    span{
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
</style>