import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/global.scss'
import globalTenantConfig from "@/config/globalTenantConfig"

const app = createApp(App)
app.config.globalProperties.$globalConfig = globalTenantConfig
app.use(ElementPlus).use(store).use(router).mount('#app')
