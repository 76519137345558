<script setup>
import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig: { sectionTwo } } = useGetGlobalConfig()
</script>

<template>
  <div class="section-2">
    <div class="main-title">
      <div>{{ sectionTwo.mainTitle }}</div>
      <div class="line"></div>
    </div>
    <div class="main-tips">{{ sectionTwo.mainTips }}</div>
    <div class="example-list">
      <div class="example-item" v-for="(item, index) in sectionTwo.travleList" :key="index">
        <img class="example-img" :src="item.img"/>
        <div class="example-title">{{ item.title }}</div>
        <div class="example-info">{{ item.tips }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-2{
  padding: 100px 85px;
  .main-title{
    font-size: 36px;
    color: rgb(51, 51, 51);
    font-weight: 700;
    display: flex;
    .line {
      width: 50px;
      height: 6px;
      background: #ff7307;
      margin: 25px 0 0 30px;
    }
  }
  .main-tips{
    font-size: 25px;
    color: rgb(153, 153, 153);
    line-height: 60px;
    text-align: left;
  }
  .example-list{
    display: flex;
    flex-wrap: wrap;
    .example-item{
      flex: 1;
      padding: 0 15px;
      margin-top: 30px;
      .example-img{
        width: 100%;
        height: auto;
      }
      .example-title{
        font-size: 28px;
        color: #333;
        margin-top: 20px;
      }
      .example-info{
        font-size: 20px;
        color: #999;
        margin-top: 20px;
      }
    }
  }
}
</style>