export default {
  headerNav: {
    logo: 'https://www.autumnfruittech.cn/image/logo.png'
  },
  sectionOne: {
    bannerBg: 'https://design.cecdn.yun300.cn/p_2110135047/homevideo.mp4',
    mainTitle: '鄂约出行快乐随行',
    englishMainTitle: 'E YUE TRAVEL',
    mainTips: '关于鄂约',
    codeImg: 'https://www.autumnfruittech.cn/image/codeImage.jpg'
  },
  sectionTwo: {
    mainTitle: '鄂约出行·安全又好骑的共享电车',
    mainTips: '5G智能交通大数据平台',
    travleList: [
      {
        img: 'https://img203.yun300.cn/img/sulution01.png?tenantId=261236&viewType=1&k=1686281733000',
        title: '实名认证',
        tips: '实名认证用车，有效保证信息安全性'
      },
      {
        img: 'https://www.xiaoantech.com/repository/image/ada0f111-b122-44a6-bf68-cabeecfbc00e.jpg?t=1691048827152',
        title: '安全好骑',
        tips: '不断升级产品，安全又好骑的用车体验'
      },
      {
        img: 'https://www.xiaoantech.com/repository/image/zI9fnWvgSEenVKHjMJjqiQ.png?k=1686281733000',
        title: '微信扫码用车',
        tips: '确保用户用车安全，便捷使用'
      },
      {
        img: 'https://www.xiaoantech.com/repository/image/e69f0a80-184a-4bb5-80d8-b82365fa7c82.jpg?t=1691048751285',
        title: '倡导安全文明骑行',
        tips: '倡导城市安全用车，共建良好骑行秩序'
      }
    ]
  },
  sectionThree: {
    carIntroduceList: [
      {
        mainTitle: '微信扫码用车',
        mainTips: '',
        mainImg: 'https://www.xiaoantech.com/repository/image/zI9fnWvgSEenVKHjMJjqiQ.png?k=1686281733000',
        mainList: [
          {
            title: '',
            tips: '鄂约出行定期对用户群进行产品使用流程和安全驾驶宣教，确保用户用车安全。用户可通过"鄂约出行"微信小程序进行扫码用车，用户进行实名注册后，无需缴纳押金，通过软件便能查看当前车辆的状态、电量、续航等信息。扫描车身的二维码或输入车辆编号，点击"立即用车"即可骑行'
          }
        ]
      },
      {
        mainTitle: '实名认证',
        mainTips: '',
        mainImg: 'https://img203.yun300.cn/img/sulution01.png?tenantId=261236&viewType=1&k=1686281733000',
        mainList: [
          {
            title: '',
            tips: '鄂约出行设定用户年龄区间，采用用户实名注册，使用人年龄严格控制在 16 周岁-65 周岁之间，系统自动拒绝非展业年龄段的非目标用户使用，用户线上注册使用共享电单车时，需要提供本人有效身份信息，该信息的审核具备公安部门备案认可，有效保证信息的安全性。'
          }
        ]
      },
      {
        mainTitle: '规范管理，站点还车',
        mainTips: '',
        mainImg: 'https://www.xiaoantech.com/repository/image/H3r3Mdf-SYW4kQ1f3kfxjw.jpg?k=1686281733000',
        mainList: [
          {
            title: '',
            tips: '规范管理，站点还车，所有用户用车完毕后，需行至还车服务点方可进行还车，有效避免了乱停乱放对城市形象的影响。结合蓝牙道钉，电子标识牌等技术，使还车服务摆放更加规范。\n'
          }
        ]
      },
    ]
  },
  sectionFour: {
    mainTitle: '打造便民，高效出行服务平台',
    mainTips: '便民出行',
    specialList: [
      {
        img: 'https://dytz-website.oss-accelerate.aliyuncs.com/homesite_forntend_v3/dycx/dycx8.png',
        title: '安全出行'
      },
      {
        img: 'https://dytz-website.oss-accelerate.aliyuncs.com/homesite_forntend_v3/dycx/dycx9.png',
        title: '随时出发'
      },
      {
        img: 'https://dytz-website.oss-accelerate.aliyuncs.com/homesite_forntend_v3/dycx/dycx11.png',
        title: '优质售后服务'
      }
    ]
  },
  footer: {
    footerLogo: 'https://www.autumnfruittech.cn/image/footerLogo.png',
    customerPhone: '027-59765090',
    copyright: '©2023 成华区秋实网络科技服务部 All Rights Reserved',
    internetNumber: '蜀ICP备2023023526号-2',
    recordNumber: ''
  }
}