<script setup>
import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig: { headerNav } } = useGetGlobalConfig()
const goJump = () => {
  window.open('https://ebike-dianlv.xiaoantech.com/frontend/admin/index.html')
}
</script>

<template>
  <div class="header-nav">
    <div class="header-icon">
      <img :src="headerNav.logo">
    </div>
    <div class="manage" @click="goJump">后台登录</div>
  </div>
</template>

<style scoped lang="scss">
.header-nav{
  background: rgba(0,0,0, 0.5);
  padding: 15px 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.header-icon{
  margin-top: 6px;
}
.manage{
  cursor: pointer;
  color: #fff;
}
</style>