<script setup>

import useGetGlobalConfig from "@/hooks/global";
const { $globalConfig: { sectionFour } } = useGetGlobalConfig()
</script>

<template>
  <div class="section-4">
    <div class="main-title">{{ sectionFour.mainTitle }}</div>
    <div class="main-tips">{{ sectionFour.mainTips }}</div>
    <div class="main-list">
      <div class="item" v-for="(item, index) in sectionFour.specialList" :key="index">
        <img class="item-img" :src="item.img"/>
        <div class="item-title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-4{
  padding: 100px 85px;
  .main-title{
    font-size: 36px;
    color: rgb(51, 51,51);
    font-weight: 700;
    text-align: center;
  }
  .main-tips{
    font-size: 25px;
    color: rgb(153, 153, 153);
    text-align: center;
    line-height: 60px;
  }
  .main-list{
    display: flex;
    .item{
      flex: 1;
      padding: 70px 90px;
      text-align: center;
      margin: 30px;
      box-shadow: 0 0 14px 0 rgba(51,51,51,.06);
      .item-img{
        width: 136px;
        height: auto;
      }
      .item-title{
        font-size: 28px;
        color: #333;
        margin-top: 40px;
      }
    }
  }
}
</style>